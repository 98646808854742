import React from "react"
import { Link, graphql } from "gatsby"

import HelpForm from "../components/helpform"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Contact Us at Sunset Health" />
        <section className="uni-section about-hero">
            <div className="uni-container flex-space-between flex-vertical-reverse">
                <div className="uni-col">
                    <h1 className="uni-h1">Get in contact with us</h1>
                    <p className="uni-hero-p">Let’s talk, we’re here to help or request a demo with one of our representatives.</p>
                </div>
                <img src={require('../../content/assets/hero-content/contact-us.svg')} alt="Contact us image" className="uni-hero_img contact-us-img" />
            </div>
        </section>
        <section className="uni-section contact-hero border">
            <p className="contact-hero-h">Email us at <span className="color-457B9D">hello@sunset.health</span> about your needs or concerns.</p>
            <div className="uni-container flex-horizontal-center-stretch">
              <div className="uni-grid-col flex-top-center align-center">
                  <img src={require('../../content/assets/icons/chatting.svg')} alt="email for general inquiries" class="uni-icon-48-height" />
                  <h5>General Inquiries</h5>
              </div>
              <div className="uni-grid-col flex-top-center align-center">
                  <img src={require('../../content/assets/icons/care.svg')} alt="email for partnership" class="uni-icon-48-height" />
                  <h5>Partnerships</h5>
              </div>
              <div className="uni-grid-col flex-top-center align-center">
                  <img src={require('../../content/assets/icons/mobile-phone.svg')} alt="email for technical issues" class="uni-icon-48-height" />
                  <h5>Technical Issues</h5>
              </div>
              <div className="uni-grid-col flex-top-center align-center">
                  <img src={require('../../content/assets/icons/megaphone.svg')} alt="email for press inquiries" class="uni-icon-48-height" />
                  <h5>Press Inquiries</h5>
              </div>
              <div className="uni-grid-col flex-top-center align-center">
                  <img src={require('../../content/assets/icons/employees.svg')} alt="email for sunset employees" class="uni-icon-48-height" />
                  <h5>Sunset for Employees</h5>
              </div>
            </div>
        </section>
        <section className="uni-section">
            <div className="uni-container flex-space-between flex-vertical">
                <div className="uni-col uni-form-instructions-col">
                    <h3 className="uni-h3">If you have questions about Sunset Health in general, let us know.</h3>
                    <br />
                    <p className="uni-p">We value hearing feedback from our users, our collaborators, or anyone who wants to join us in revolutionizing the way we battle burnout.</p>
                    <p class="uni-p">Please fill out the form and one our representatives we get back to you shortly.</p>
                </div>
                <div className="uni-col uni-form-col">
                    <HelpForm />
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
